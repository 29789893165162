import { Box, Button, Input, Textarea, Typography } from "@mui/joy";

import { IoMail } from "react-icons/io5";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";

import handleContact from "../../handlers/ses-handler";

const onSubmit = (e) => {
  e.preventDefault();
  const formData = new FormData(e.currentTarget);
  const data = Object.fromEntries(formData);
  handleContact(data);
};

const Contact = () => {
  return (
    <Box
      className="w-full flex justify-center items-center gap-8 py-8"
      sx={{
        backgroundColor: "#F2F3F7",
        marginTop: { xs: "1rem", md: "3rem" },
      }}
      id="contact"
    >
      <Box
        className="flex flex-col w-full"
        sx={{ maxWidth: "var(--content-width)", px: { xs: 4, xl: 0 } }}
      >
        <Box className="flex flex-grow gap-4 mx-auto flex-wrap justify-between w-full mt-8">
          <Box className="flex flex-col gap-2" sx={{ flexGrow: 2 }}>
            <Typography
              textColor="var(--primary-color)"
              level="h5"
              fontWeight={600}
            >
              Kontakieren Sie uns
            </Typography>
            <Typography textColor="#111821" level="h4" fontWeight={600}>
              Gerne beantworten wir Ihre Fragen
            </Typography>
          </Box>
          <Box className="flex flex-grow flex-wrap">
            <Box className="flex flex-grow gap-4">
              <MdLocationOn size={25} color={"var(--primary-color)"} />
              <Box className="flex flex-col">
                <Typography textColor="#747F94" level={"body1"}>
                  Gasteigerstraße 21c
                </Typography>
                <Typography textColor="#747F94" level={"body1"}>
                  6380 St. Johann i. T.
                </Typography>
                <Typography textColor="#747F94" level={"body1"}>
                  Österreich
                </Typography>
              </Box>
            </Box>
            <Box className="flex flex-col gap-4">
              <Box className="flex gap-4 items-center">
                <BsFillTelephoneFill size={25} color={"var(--primary-color)"} />
                <Typography
                  textColor="#747F94"
                  level={"body1"}
                  component="a"
                  href="tel:+436605767679"
                >
                  +43 660 576 767 9
                </Typography>
              </Box>
              <Box className="flex gap-4 items-center">
                <IoMail size={25} color={"var(--primary-color)"} />
                <Typography
                  textColor="#747F94"
                  level={"body1"}
                  component="a"
                  href="mailto:office@gliding-eagle.eu"
                >
                  office@gliding-eagle.eu
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <form className="flex flex-col gap-8 mt-16" onSubmit={onSubmit}>
          <Box className="grid grid-cols-3 gap-8">
            <Input
              sx={{
                gridColumn: { xs: "span 3", md: "span 1" },
                borderRadius: 8,
              }}
              color="neutral"
              placeholder="Max Mustermann *"
              size="lg"
              required
              variant="plain"
              name="name"
            />
            <Input
              sx={{
                gridColumn: { xs: "span 3", md: "span 1" },
                borderRadius: 8,
              }}
              color="neutral"
              placeholder="Deine E-Mail *"
              size="lg"
              required
              variant="plain"
              type="email"
              name="email"
            />
            <Input
              sx={{
                gridColumn: { xs: "span 3", md: "span 1" },
                borderRadius: 8,
              }}
              color="neutral"
              type="tel"
              placeholder="Handynummer Bsp. +XX XXX XXXXXXX *"
              size="lg"
              required
              variant="plain"
              name="phone"
            />
          </Box>
          <Box>
            <Textarea
              minRows={10}
              maxRows={10}
              color="neutral"
              placeholder="Frage *"
              size="lg"
              variant="plain"
              required
              name="message"
            />
          </Box>
          <Box className="flex justify-end mb-8">
            <Button
              type="submit"
              className="button--round"
              sx={{
                fontWeight: "500 !important",
                color: "white !important",
                backgroundColor: "var(--primary-color) !important",
              }}
            >
              absenden
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;

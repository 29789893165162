import { Box } from "@mui/joy";
import AboutOurCompany from "../content/AboutOurCompany";
import NextLevelFlightSchool from "../content/NextLevelFlightSchool";
import Pricing from "../content/Pricing";
import Thumbnail from "../content/Thumbnail";
import Contact from "../content/Contact";

const Content = () => {
  return (
    <Box
      className="w-full flex flex-col items-center justify-center"
      sx={{ backgroundColor: "#fafafc" }}
    >
      <Thumbnail />
      <AboutOurCompany />
      <Box
        className="w-full"
        sx={{
          backgroundImage: "url('/camo.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",

          mb: { xs: 35, md: 48 },
        }}
      >
        <NextLevelFlightSchool />
        <Pricing />
      </Box>
      <Contact />
    </Box>
  );
};

export default Content;

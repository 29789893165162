import { extendTheme as extendJoyTheme } from "@mui/joy/styles";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendMuiTheme,
} from "@mui/material/styles";
import { responsiveFontSizes } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

import Header from "./components/layout/Header";
import Content from "./components/layout/Content";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Legal from "./components/pages/Legal";
import Footer from "./components/layout/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const fontFamily = "Montserrat, sans-serif";
const joyTheme = extendJoyTheme({
  cssVarPrefix: "mui",
  typography: {
    fontFamily,
  },
});

const muiTheme = extendMuiTheme({
  typography: {
    fontFamily,
    body: { fontFamily },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
  },
});

const theme = responsiveFontSizes(deepmerge(joyTheme, muiTheme));

const App = () => {
  return (
    <CssVarsProvider theme={theme}>
      <div className="w-full flex flex-col" style={{ minHeight: "100vh" }}>
        <Router>
          <Header />
          <Routes>
            <Route path="/*" element={<Content />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/legal" element={<Legal />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </CssVarsProvider>
  );
};

export default App;

import { Box, Button } from "@mui/joy";
import LogoSlogan from "../icons/LogoSlogan";

import { AiOutlineFileUnknown, AiOutlineUser } from "react-icons/ai";

const Thumbnail = () => {
  return (
    <Box
      className="w-full flex flex-col items-center justify-center"
      sx={{
        backgroundImage: "url('/hero.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        marginTop: "-8rem",
        height: "45.6rem",
        backgroundPosition: "center",
      }}
    >
      <Box
        className="container mt-15 text-center"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className="w-full flex justify-center">
          <LogoSlogan className="w-3/5" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: "1rem",
            mt: { xs: "1rem", md: "0" },
          }}
        >
          <Button className="button--round">
            <AiOutlineFileUnknown color="#f42656" />
            Mehr Erfahren
          </Button>
          <Button
            className="button--round"
            sx={{
              color: "white !important",
              backgroundColor: "#f42656 !important",
            }}
          >
            <AiOutlineUser color="white" />
            Anmelden
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Thumbnail;

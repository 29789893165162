import React from "react";
import { Box, Card, Typography } from "@mui/joy";

import Link from "@mui/material/Link";

const Legal = () => {
  return (
    <Box
      className="w-full flex flex-col items-center justify-center flex-grow"
      sx={{ backgroundColor: "#F0F0F0" }}
    >
      <Card className="bg-white container m-10 ">
        <Typography
          level="h4"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
          fontWeight="bold"
        >
          Impressum
        </Typography>
        <Typography
          level="caption"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginBottom="1rem"
          color="#232023"
        >
          Informationen und Offenlegung gemäß §5 (1) ECG, § 25 MedienG, § 63
          GewO und § 14 UGB
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          color="#232023"
          fontWeight="bold"
        >
          Webseitenbetreiber: <Typography level="body2">BHNsoft OG</Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
          fontWeight="bold"
        >
          Firmenbuchnummer: <Typography level="body2">123456789</Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginBottom="0.5rem"
          color="#232023"
          fontWeight="bold"
        >
          Firmenbuchgericht:{" "}
          <Typography level="body2">Landesgericht Innsbruck</Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          color="#232023"
          fontWeight="bold"
        >
          Anschrift:{" "}
          <Typography level="body2">
            Gasteigerstraße 21c Top 8, 6380 St. Johann in Tirol
          </Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          color="#232023"
          fontWeight="bold"
        >
          UID-Nr: <Typography level="body2">U123456789</Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
          fontWeight="bold"
        >
          Gewerbeaufsichtsbehörde:{" "}
          <Typography level="body2">
            Bezirkshauptmannschaft Kitzbühel
          </Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
          fontWeight="bold"
        >
          Mitgliedschaften: <Typography level="body2">WKÖ</Typography>
        </Typography>
        <Typography
          level="h6"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          marginBottom="0.5rem"
          color="#232023"
        >
          Kontaktdaten
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
        >
          Telefonnummer:{" "}
          <Typography level="body2">+43 660 576 767 9</Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
        >
          Email: <Typography level="body2">office@bhn.at</Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
          fontWeight="bold"
          marginTop="1rem"
        >
          Anwendbare Rechtsvorschrift:{" "}
          <Typography level="body2">www.ris.bka.gv.at</Typography>
        </Typography>
        <Typography
          level="body1"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          color="#232023"
          fontWeight="bold"
        >
          Berufsbezeichnung:{" "}
          <Typography level="body2">IT Dienstleistungen</Typography>
        </Typography>
        <Typography
          level="h6"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          marginBottom="0.5rem"
          color="#232023"
        >
          Online Streitbeilegung
        </Typography>
        <Typography level="body1">
          Verbraucher, welche in Österreich oder in einem sonstigen
          Vertragsstaat der ODR-VO niedergelassen sind, haben die Möglichkeit
          Probleme bezüglich dem entgeltlichen Kauf von Waren oder
          Dienstleistungen im Rahmen einer Online-Streitbeilegung (nach OS,
          AStG) zu lösen. Die Europäische Kommission stellt eine Plattform
          hierfür bereit: https://ec.europa.eu/consumers/odr
        </Typography>
        <Typography
          level="h6"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          marginBottom="0.5rem"
          color="#232023"
        >
          Urheberrecht
        </Typography>
        <Typography level="body1">
          Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich
          ist, diversen Schutzrechten (z.B dem Urheberrecht). Jegliche
          Verwendung/Verbreitung von bereitgestelltem Material, welche
          urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des
          Webseitenbetreibers.
        </Typography>
        <Typography
          level="h6"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          marginBottom="0.5rem"
          color="#232023"
        >
          Haftungsausschluss
        </Typography>
        <Typography level="body1" marginBottom="1rem">
          Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der
          Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte
          externer Links. Für den Inhalt der verlinkten Seiten sind
          ausschließlich deren Betreiber verantwortlich. Sollten Sie dennoch auf
          ausgehende Links aufmerksam werden, welche auf eine Webseite mit
          rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um
          dementsprechenden Hinweis, um diese nach § 17 Abs. 2 ECG umgehend zu
          entfernen. Die Urheberrechte Dritter werden vom Betreiber dieser
          Webseite mit größter Sorgfalt beachtet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden derartiger
          Rechtsverletzungen werden wir den betroffenen Inhalt umgehend
          entfernen.
        </Typography>
        <Typography level="caption">Stand: 01.01.2023</Typography>
        <Typography level="caption">
          Quelle:{" "}
          <Link
            href="https://ghostweb.agency/impressum-generator-oesterreich/"
            underline="hover"
            color="black"
          >
            Impressum Generator
          </Link>
        </Typography>
      </Card>
    </Box>
  );
};

export default Legal;

import { Box, Button, Typography } from "@mui/joy";

const AboutOurCompany = () => {
  return (
    <Box
      className="mx-auto flex justify-center items-center gap-8 py-8 flex-wrap md:flex-nowrap"
      sx={{
        maxWidth: "var(--content-width)",
        marginBottom: "28rem",
        marginTop: "4rem",
        px: "2rem",
      }}
    >
      <Box className="flex flex-col gap-3 w-full md:w-1/2 order-1">
        <Typography
          level="h6"
          sx={{
            fontWeight: "500",
            color: "var(--primary-color)",
            letterSpacing: "2px",
          }}
        >
          GLEITSCHIRMERLEBNIS VEREINFACHEN
        </Typography>
        <Typography level="h4" fontWeight="600" lineHeight={2}>
          Bequeme{" "}
          <span style={{ color: "var(--primary-color)" }}>Flugbuchführung</span>{" "}
          und <span style={{ color: "var(--primary-color)" }}>Analyse</span> für
          Piloten
        </Typography>
        <Typography level="body1" fontWeight={400}>
          Mit unserem Flugbuch für Gleitschirmpiloten können Sie einfach und
          bequem Flugdetails wie Start- und Landeorte, Flugdauer und Entfernung
          aufzeichnen und analysieren. Die intuitive Bedienung und die
          GPS-Technologie ermöglichen es Ihnen, Ihre Flüge protokollieren und
          bewerten zu können. Wir freuen uns, Ihr Gleitschirm-Erlebnis noch
          heute zu vereinfachen.
        </Typography>
        <Box className="flex mt-2">
          <Button
            className="button--round"
            sx={{
              fontWeight: "500 !important",
              color: "white !important",
              backgroundColor: "var(--primary-color) !important",
            }}
          >
            mehr erfahren
          </Button>
        </Box>
      </Box>
      <Box className="w-full md:w-1/2 md:order-1 flex justify-center">
        <img src="/glider-1.png" alt="glider" />
      </Box>
    </Box>
  );
};

export default AboutOurCompany;

import { Box, Typography, Card, Button } from "@mui/joy";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";

import { BsCheck, BsArrowRight } from "react-icons/bs";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const abos = [
  {
    name: "Anfänger",
    price: 2.99,
    description:
      "Für Hobbyflieger und Anfänger, die sich mit der Software vertraut machen wollen oder sich mit weniger Daten zufrieden geben.",
    included: [
      "10 Flüge",
      "Genaustes Tracking",
      "Statistiken",
      "Max. 2 Gleiter",
    ],
    excluded: ["Live Wetterdaten", "Fluganalyse", "Schülerverwaltung"],
  },
  {
    name: "Profi",
    price: 5.99,
    description:
      "Für alle, die auf unsere erweiterte Fluganalyse und unsere live Wetterdaten nicht verzichten wollen.",
    included: [
      "Unbegrenzte Flüge",
      "Genaustes Tracking",
      "Statistiken",
      "Max. 10 Gleiter",
      "Live Wetterdaten",
      "Fluganalyse",
    ],
    excluded: ["Schülerverwaltung"],
  },
  {
    name: "Flugschule",
    noprice: true,
    price: "Preis auf Anfrage",
    description:
      "Für Flugschulen, die ihre Schüler mit unserem Tracking und der Software unterstützen wollen.",
    included: [
      "Unbegrenzte Flüge",
      "Genaustes Tracking",
      "Statistiken",
      "Unbegrenzte Gleiter",
      "Live Wetterdaten",
      "Fluganalyse",
      "Schülerverwaltung",
    ],
  },
];

const Pricing = () => {
  return (
    <Box
      className="w-full mx-auto flex flex-col"
      sx={{
        maxWidth: "var(--content-width)",
        transform: "translateY(30%)",
        mt: -58,
        pt: 5,
      }}
      id="pricing"
    >
      <Typography level="h4" textAlign={"center"} sx={{ color: "white" }}>
        Flexible Preismodelle basierend auf deinen
      </Typography>
      <Typography
        level="h4"
        textAlign={"center"}
        sx={{ color: "var(--primary-color)" }}
      >
        Erfahrungen und Bedürfnissen
      </Typography>
      <Typography
        textAlign={"center"}
        level="body1"
        sx={{ mt: 3, color: "#E6E8EC" }}
      >
        Kein passendes Angebot? Kontaktieren Sie uns!
      </Typography>
      <Box
        className="flex justify-center mt-8 p-4"
        sx={{ "--swiper-navigation-size": { xs: "2rem", md: "3rem" } }}
      >
        <Swiper
          slidesPerView={1.5}
          spaceBetween={20}
          pagination={{ clickable: true }}
          navigation
          scrollbar={{ draggable: true }}
          modules={[Pagination, Navigation, Scrollbar]}
          centerInsufficientSlides
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 20 },
            900: { slidesPerView: 3, spaceBetween: 20 },
          }}
        >
          {abos.map((abo, i) => (
            <SwiperSlide key={i}>
              <Card
                sx={{
                  padding: "2rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                }}
              >
                <Typography level="h6" fontWeight={900}>
                  {abo.name}
                </Typography>
                <Typography level="body2" sx={{ mt: 2 }}>
                  {abo.description}
                </Typography>
                <Box className="mb-2">
                  <Typography
                    level="body1"
                    sx={{ mt: 2 }}
                    className="mb-2 font-bold"
                  >
                    Was ist dabei:
                  </Typography>
                  {abo.included.map((item, j) => (
                    <Box className="flex items-center gap-3" key={j}>
                      <BsCheck color="var(--primary-color)" />
                      {item}
                    </Box>
                  ))}
                </Box>
                <Typography
                  level="h6"
                  sx={{ mb: 2, color: "var(--primary-color)", mt: "auto" }}
                >
                  {abo.noprice
                    ? abo.price
                    : parseFloat(abo.price).toLocaleString() + " €"}
                  {!abo.noprice && " / Monat"}
                </Typography>
                <Button
                  className="button--round flex items-center"
                  sx={{
                    fontWeight: "500 !important",
                    color: "black !important",
                    backgroundColor: "white",
                    whiteSpace: "nowrap",
                    border: "1px solid var(--primary-color) !important",
                    "&:hover": {
                      color: "white !important",
                      backgroundColor: "var(--primary-color) !important",
                    },
                  }}
                >
                  jetzt abheben
                  <BsArrowRight />
                </Button>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box
        className="absolute hidden xl:block"
        sx={{
          bottom: 0,
          right: 0,
          transform: "translate(40%, 10%)",
        }}
      >
        <img src="/Vector2.svg" alt="dots" role="presentation" />
      </Box>
    </Box>
  );
};

export default Pricing;

import { Box, Typography, Button, Card } from "@mui/joy";

const NextLevelFlightSchool = () => {
  return (
    <Card
      className="mx-auto p-4"
      sx={{
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "var(--content-width)",
        transform: { xs: "translateY(-30%)", md: "translateY(-50%)" },
      }}
      id="flightschools"
    >
      <Box className="w-full flex justify-center items-center gap-8 py-12 px-6 relative flex-wrap md:flex-nowrap">
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            transform: "translate(-65%, -50%)",
          }}
          className="hidden xl:block"
        >
          <img src="/Vector1.svg" alt="dots" role={"presentation"} />
        </Box>
        <Box className="w-full md:w-1/2 flex justify-center">
          <img src="/Frame.png" alt="glider" />
        </Box>
        <Box className="flex flex-col gap-3 w-full md:w-1/2">
          <Typography
            level="h6"
            sx={{
              fontWeight: "500",
              color: "var(--primary-color)",
              letterSpacing: "2px",
            }}
          >
            STEIGEN WIR GEMEINSAM AUF
          </Typography>
          <Typography level="h4" fontWeight="600" lineHeight={2}>
            <span style={{ color: "var(--primary-color)" }}>Next-Level</span>{" "}
            Flugschule
          </Typography>
          <Typography level="body1" fontWeight={400} lineHeight={1.8}>
            Vereinfachen Sie Ihre Schulungsverwaltung und erweitern Sie Ihr
            Potenzial als Flugschule von morgen! Unsere digitale
            Schulungsverwaltung ermöglicht eine unkomplizierte Verwaltung von
            Schülerdaten und gibt Fluglehrern einen klaren Überblick über den
            Fortschritt jedes Schülers. Aktuelle Informationen zu Kursen und
            Schulungen sind jederzeit verfügbar, und das System kann einfach in
            Ihre bestehenden Prozesse integriert werden. Optimieren Sie Ihren
            Schulungsprozess und steigern Sie die Effizienz Ihrer Flugschule mit
            uns!
          </Typography>
          <Box className="flex mt-2">
            <Button
              className="button--round"
              sx={{
                fontWeight: "500 !important",
                color: "white !important",
                backgroundColor: "var(--primary-color) !important",
              }}
            >
              jetzt kontaktieren
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default NextLevelFlightSchool;

const LogoSlogan = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 200 841.89 205.28"
      {...props}
      style={{ fill: props.color || "#fff", ...props.style }}
    >
      <path d="M75.17,258.22l-4.73,26.69a2.09,2.09,0,0,0,.38,1.67,1.61,1.61,0,0,0,1.37.73H91.3a1.64,1.64,0,0,0,1.76-1.63l1.53-8.41H87.94a4.74,4.74,0,0,1-2.37-.6,5.71,5.71,0,0,1-1.8-1.59,6.37,6.37,0,0,1-1-2.32,6.8,6.8,0,0,1-.07-2.7l.84-4.81h29.27l-4.12,23.18a17.4,17.4,0,0,1-1.84,5.19,16.62,16.62,0,0,1-3.13,4.08,13.85,13.85,0,0,1-4.17,2.66,12.77,12.77,0,0,1-4.85.94H61a7.22,7.22,0,0,1-3.44-.86,7.66,7.66,0,0,1-2.67-2.36,9.68,9.68,0,0,1-1.53-3.39,9.19,9.19,0,0,1-.08-3.95l7.42-41.45a9.75,9.75,0,0,1,3.06-5.83,8.12,8.12,0,0,1,5.65-2.24h46.17l-2.44,14H66.23Z" />
      <path d="M139.08,287.31h33.18l-2.53,14h-51l10.7-60.08h17.81Z" />
      <path d="M178,301.3l10.7-60.08h16.05L194,301.3Z" />
      <path d="M257.1,301.3H207.8l10.7-60.08H270a8,8,0,0,1,3.94,1,8.75,8.75,0,0,1,3,2.66,11.36,11.36,0,0,1,1.72,3.82,10.76,10.76,0,0,1,.15,4.46l-6,33.65a18.52,18.52,0,0,1-2,5.83,18,18,0,0,1-3.52,4.59,15.47,15.47,0,0,1-4.66,3A14.42,14.42,0,0,1,257.1,301.3Zm-1.91-14c.92,0,1.51-.54,1.76-1.63l5-28.07a2.25,2.25,0,0,0-.34-1.67,1.57,1.57,0,0,0-1.34-.73H232l-5.66,32.1Z" />
      <path d="M282.1,301.3l10.7-60.08h16.06L298.15,301.3Z" />
      <path d="M336.45,253.76,328,301.3h-16l10.7-60.08h27.68l8.48,47.55,8.41-47.55h16.05l-10.7,60.08h-27.6Z" />
      <path d="M405.4,287.31h19.11a1.64,1.64,0,0,0,1.76-1.63l1.53-8.41h-6.65a4.74,4.74,0,0,1-2.37-.6,5.71,5.71,0,0,1-1.8-1.59,6.37,6.37,0,0,1-1-2.32,6.8,6.8,0,0,1-.07-2.7l.84-4.81H446l-4.13,23.18a17.4,17.4,0,0,1-1.84,5.19,16.38,16.38,0,0,1-3.13,4.08,13.73,13.73,0,0,1-4.17,2.66,12.77,12.77,0,0,1-4.85.94H394.24a7.22,7.22,0,0,1-3.44-.86,7.66,7.66,0,0,1-2.67-2.36,9.68,9.68,0,0,1-1.53-3.39,9.35,9.35,0,0,1-.08-3.95l7.42-41.45a9.75,9.75,0,0,1,3.06-5.83,8.12,8.12,0,0,1,5.65-2.24h46.17l-2.44,14h-36c-.92,0-1.5.55-1.76,1.63l-5,28.07a2.09,2.09,0,0,0,.38,1.67A1.61,1.61,0,0,0,405.4,287.31Z" />
      <path d="M471.22,289.28l8.56-48.06h50l-2.52,14H483.91l8.94,3-1.15,6.52h28.59l-2.29,13H489.33l-1.22,7.13a2.17,2.17,0,0,0,.38,1.67,1.64,1.64,0,0,0,1.38.73h31.65l-2.45,14H480a8,8,0,0,1-3.94-1,8.65,8.65,0,0,1-3-2.66,11.21,11.21,0,0,1-1.72-3.86A11,11,0,0,1,471.22,289.28Z" />
      <path d="M588.1,301.3H572.05l3.05-17H551.94l-3.06,17h-16l9.25-52a9.75,9.75,0,0,1,3.1-5.83,8.25,8.25,0,0,1,5.69-2.24H588a8,8,0,0,1,3.93,1,9.07,9.07,0,0,1,3,2.66,10.52,10.52,0,0,1,1.76,8.28Zm-10.47-31.07,2.21-12.62a2.14,2.14,0,0,0-.38-1.67,1.65,1.65,0,0,0-1.37-.73h-19.5a1.65,1.65,0,0,0-1.76,1.63l-2.44,13.39Z" />
      <path d="M619,287.31h19.11a1.64,1.64,0,0,0,1.76-1.63l1.53-8.41h-6.65a4.74,4.74,0,0,1-2.37-.6,5.6,5.6,0,0,1-1.8-1.59,6.21,6.21,0,0,1-1-2.32,7,7,0,0,1-.08-2.7l.84-4.81h29.28l-4.13,23.18a17.39,17.39,0,0,1-1.83,5.19,16.42,16.42,0,0,1-3.14,4.08,13.68,13.68,0,0,1-4.16,2.66,12.82,12.82,0,0,1-4.86.94H607.82a7.22,7.22,0,0,1-3.44-.86,7.66,7.66,0,0,1-2.67-2.36,9.7,9.7,0,0,1-1.54-3.39,9.36,9.36,0,0,1-.07-3.95l7.42-41.45a9.79,9.79,0,0,1,3.05-5.83,8.15,8.15,0,0,1,5.66-2.24H662.4l-2.45,14H624c-.92,0-1.5.55-1.76,1.63l-5,28.07a2.13,2.13,0,0,0,.39,1.67A1.61,1.61,0,0,0,619,287.31Z" />
      <path d="M685.87,287.31H719l-2.52,14h-51l10.7-60.08h17.82Z" />
      <path d="M743.35,287.31h33.87l-2.53,14H724.78l10.7-60.08h49.91l-2.44,14H749l-1.61,9H776l-2.52,14.07H745Z" />
      <path d="M65.73,337.11h3.52l10.61,26.24H76.68L74,356.67H61l-2.7,6.68H55.12Zm7.12,16.75-5.36-13.23-5.36,13.23Z" />
      <path d="M101,340.63a12.95,12.95,0,0,1,18.52.22l-2.36,2a8.88,8.88,0,0,0-7-3,9.05,9.05,0,0,0-6.93,2.95,11.57,11.57,0,0,0,0,14.95,9,9,0,0,0,6.93,2.95,8.88,8.88,0,0,0,7-3l2.36,2a13,13,0,0,1-18.53.22,14.51,14.51,0,0,1,0-19.19Z" />
      <path d="M139.91,363.35V337.11H143v11.54h15.63V337.11h3.11v26.24h-3.11V351.46H143v11.89Z" />
      <path d="M183.89,363.35V337.11H187v26.24Z" />
      <path d="M209.11,363.35V337.11h18.45v2.81H212.23v8.73h13.83v2.81H212.23v9.07h15.33v2.82Z" />
      <path d="M246.53,337.11h3.18l9,22.67,9-22.67h3.19l-10.42,26.24H257Z" />
      <path d="M290.72,363.35V337.11h18.44v2.81H293.83v8.73h13.83v2.81H293.83v9.07h15.33v2.82Z" />
      <path d="M356.06,337.11h3.11l15.63,21.25V337.11h3.11v26.24H374.8l-15.63-21.26v21.26h-3.11Z" />
      <path d="M400,363.35V337.11h18.44v2.81H403.14v8.73H417v2.81H403.14v9.07h15.33v2.82Z" />
      <path d="M437.44,337.11h3.11l6.19,21.51L453,337.11h3.52l6.23,21.51,6.18-21.51H472l-7.61,26.24H461l-6.26-21.63-6.26,21.63h-3.41Z" />
      <path d="M517.4,363.35V337.11h3.11v11.54h15.63V337.11h3.11v26.24h-3.11V351.46H520.51v11.89Z" />
      <path d="M561.37,363.35V337.11h18.44v2.81H564.48v8.73h13.83v2.81H564.48v9.07h15.33v2.82Z" />
      <path d="M601.11,363.35V337.11h3.11v26.24Z" />
      <path d="M625.13,350.23a13.21,13.21,0,0,1,3.68-9.6,12.46,12.46,0,0,1,9.33-3.79,12.25,12.25,0,0,1,9.52,4l-2.36,2a9.19,9.19,0,0,0-7.16-3,9.38,9.38,0,0,0-7.1,2.95,10.42,10.42,0,0,0-2.79,7.48,10.32,10.32,0,0,0,2.83,7.47,9.48,9.48,0,0,0,7.17,2.95,9.61,9.61,0,0,0,6.49-2.33V352h-7.35v-2.81h10.46V359.6a12.34,12.34,0,0,1-9.6,4,12.62,12.62,0,0,1-9.41-3.79A13.14,13.14,0,0,1,625.13,350.23Z" />
      <path d="M668.81,363.35V337.11h3.11v11.54h15.63V337.11h3.11v26.24h-3.11V351.46H671.92v11.89Z" />
      <path d="M710.45,337.11h22v2.81H723v23.43H719.9V339.92h-9.45Z" />
      <path d="M750.34,360.31l2.17-2.18a9.33,9.33,0,0,0,6.79,2.52,7.65,7.65,0,0,0,4.44-1.15,3.54,3.54,0,0,0,1.67-3.05,3.37,3.37,0,0,0-1.09-2.53,6.91,6.91,0,0,0-2.7-1.56c-1.07-.35-2.24-.75-3.5-1.22s-2.43-.93-3.51-1.42a6.79,6.79,0,0,1-2.7-2.25,6.13,6.13,0,0,1-1.08-3.65,6.25,6.25,0,0,1,2.28-5.1,9.43,9.43,0,0,1,6.15-1.88,12.82,12.82,0,0,1,4.8.88,8,8,0,0,1,3.37,2.42l-2.14,2.18a5.41,5.41,0,0,0-2.45-1.84,9.59,9.59,0,0,0-3.69-.68,6.79,6.79,0,0,0-3.94,1,3.23,3.23,0,0,0-1.46,2.81,3.29,3.29,0,0,0,1.5,2.77,12.26,12.26,0,0,0,3.63,1.75c1.43.43,2.86.91,4.3,1.44a9,9,0,0,1,3.65,2.45,6.09,6.09,0,0,1,1.5,4.22,6.49,6.49,0,0,1-2.47,5.38,10.51,10.51,0,0,1-6.71,2Q753.56,363.61,750.34,360.31Z" />
    </svg>
  );
};

export default LogoSlogan;
